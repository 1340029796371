:root {
  @each $theme-colors, $value in $theme-colors {
    --theme-#{$theme-colors}: #{$value};
  }

  @each $theme-colors-extended, $value in $theme-colors-extended {
    --theme-#{$theme-colors-extended}: #{$value};
  }
  
  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }	
}

html[dir=rtl]{
    body{
        text-align: right;
        direction: rtl;
    }
}
